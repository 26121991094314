<template>
  <swelling-template
    :facial-swelling="facialSwelling"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import SwellingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/swelling/SwellingTemplate';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('facialSwelling')];

export default {
  name: 'Swelling',
  components: {
    SwellingTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    facialSwelling() {
      this.showNextStep();
    }
  }
};
</script>
